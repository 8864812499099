<mat-toolbar>
  <span>Venkatarami Reddy Alla</span>
  <span class="example-spacer"></span>
  <button mat-button>
    Home
  </button>
  <button  mat-button>
    About
  </button>
  <button  mat-button>
    Resume
  </button>
  <button  mat-button>
    Contact
  </button>

  <button mat-icon-button class="example-icon favorite-icon" aria-label="Example icon-button with heart icon">
    <mat-icon>linkedin</mat-icon>
  </button>
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with share icon">
    <mat-icon>share</mat-icon>
  </button>
</mat-toolbar>
<div parallax class="some-parallax" >
  <div fxLayout="row" fxLayoutAlign="start stretch" fxFill>
    <div style="flex: 1 1 100%; box-sizing: border-box; max-width: 50%;"></div>
    <div class="meetVenkat" style="flex: 1 1 100%; box-sizing: border-box; max-width: 50%;">Meet Venkatarami Reddy</div>
  </div>
  
</div>
<div id="mySkills" fxLayout="column" fxLayoutAlign="space-evenly stretch" >

</div>